import { createRouter, createWebHistory } from "vue-router";
import { paths } from "@/routes/path/path";
import store from "@/store";
import { getCustomPage } from "@/api/customPage/request";

// console.log(store);

let rt;
let res = null;
// try {
//   res = await getCustomPage();
//   store.state.customPage.styles = res?.data;
//
//   rt = paths[res.data.templete];
// } catch (error) {
//   rt = paths["Ready"];
// }

// rt = paths["Ready"];
// rt = paths["Apple"];
// rt = paths["RoyalTy"];
// rt = paths["Gameds"];
// rt = paths["Royal"];
// rt = paths["inBaccarat"];
// rt = paths["Road"];
// rt = paths["Kingado"];
// rt = paths["Sd_old"];
// rt = paths["Hh"];

switch (process.env.VUE_APP_SERVICE_TYPE) {
  case "AQUA":
    rt = paths["Ready"];
    break;
  case "AGC":
    rt = paths["Ready"];
    break;
  case "ALPS":
    rt = paths["RoyalTy"];
    break;
  case "ROYALTY":
    rt = paths["RoyalTy"];
    break;
  case "ROYAL":
    rt = paths["Royal"];
    break;
  case "인바카라":
    rt = paths["Ready"];
    break;
  case "ROAD":
    rt = paths["Ready"];
    break;
  case "GAMEDS":
    rt = paths["Rush"];
    break;
  case "RUSH":
    rt = paths["Rush"];
    break;
  case "RUSH2":
    rt = paths["Apple"];
    break;
  case "INFINITY":
    rt = paths["Infinity"];
    break;
  case "KIMCASINO":
    rt = paths["Apple"];
    break;
  case "GENESIS":
    rt = paths["Genesis"];
    break;
  default:
    rt = paths["Ready"];
    break;
}

const routes = rt;

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.onError((error) => {
  if (/ChunkLoadError:.*failed./i.test(error.message)) {
    window.location.reload();
  } else if (/Loading.*chunk.*failed./i.test(error.message)) {
    window.location.reload();
  }
});

router.beforeEach((to, from, next) => {
  // document.title = res.data?.front_title || res.data?.templete || "EDGames";
  const link = document.querySelector("[rel='icon']");
  link.setAttribute("href", to.meta.icon);
  document.title = to.meta.title;
  next();
});

export default router;
